import { Box, Flex, Heading } from '@chakra-ui/react';
import { ArrowRightIcon } from '@udacity/chakra-uds-icons';

import { ButtonLink } from '~/components/button-link';
import { LinesBannerSection } from '~/components/lines-banner-section';
import { LogosSection } from '~/components/logos-section';
import { Markdown } from '~/components/markdown';
import { SanityImage } from '~/components/sanity-image';
import { SchoolsCardSection } from '~/components/schools-card-section';
import { ShapesBanner } from '~/components/shapes-banner';
import { StandardHeroSection } from '~/components/standard-hero';
import { TestimonialSection } from '~/components/testimonial-section';
import { UdacityOutcomesSection } from '~/components/udacity-outcomes-section';
import { trackEvent } from '~/features/analytics/track-event';

import { EnterpriseOverviewTemplate } from '../model';

import { EnterpriseSuccessSection } from './enterprise-success-section';
import { UdacityContentSection } from './udacity-content-section';
import { UdacityDifferenceSection } from './udacity-difference-section';
import { UpskillingSection } from './upskilling-section';

export function EnterpriseOverview({ pageTemplateData }: { pageTemplateData: EnterpriseOverviewTemplate }) {
  const {
    testimonial,
    schools,
    schoolsSectionTitleText,
    successSectionTitle,
    contentSectionCopy,
    contentSectionStats,
    upskillingSectionImageContentBlock,
    shapesBanner,
  } = pageTemplateData;
  return (
    <Box bg='white'>
      <StandardHeroSection
        backgroundImageSlot={
          <Box
            bottom='0px'
            pointerEvents='none'
            position='absolute'
            left={{
              base: '0px',
              md: 'calc((100vw - 70rem)/2)',
            }}
            right={{
              base: '-48px',
              md: 'calc((100vw - 100rem)/2)',
            }}
            sx={{
              '> img + img': {
                display: {
                  base: 'none',
                  md: 'block',
                },
              },
            }}
            top={{
              base: 'calc(50% + 24px)',
              md: '0px',
            }}
          >
            <SanityImage
              alt=''
              image={pageTemplateData.heroImage}
              sizes='1280px'
              style={{
                objectFit: 'cover',
                objectPosition: 'right',
              }}
              fill
              priority
            />
            <SanityImage
              alt=''
              image={pageTemplateData.heroBackgroundImage}
              sizes='1280px'
              style={{
                transform: 'translate(200px)',
              }}
              fill
              priority
            />
          </Box>
        }
        contentSlot={
          <>
            {pageTemplateData.pageTitle && (
              <Heading as='h1' size='marketing-5xl'>
                {pageTemplateData.pageTitle}
              </Heading>
            )}
            {pageTemplateData.pageSubtitle && (
              <Heading as='h2' color='accent.sky.500' order='-1' size='h4'>
                {pageTemplateData.pageSubtitle}
              </Heading>
            )}
            {pageTemplateData.pageDescription && <Markdown markdownText={pageTemplateData.pageDescription} />}
            <Flex
              alignItems='baseline'
              gap='16px'
              flexDir={{
                base: 'column',
                md: 'row',
              }}
            >
              <ButtonLink
                buttonProps={{
                  marginTop: '48px',
                  variant: 'solid',
                  colorScheme: 'sea-foam',
                  size: 'icon-right',
                  rightIcon: <ArrowRightIcon h='24px' w='24px' />,
                  width: {
                    base: '100%',
                    md: 'fit-content',
                  },
                  onClick: () => {
                    trackEvent({
                      type: 'click',
                      name: 'Enterprise CTA Clicked',
                      label: 'contact_us_clicked',
                      value: 'CTA 1',
                    });
                  },
                }}
                linkProps={{
                  href: '/get-started/enterprise',
                }}
              >
                {pageTemplateData.primaryCtaLabel}
              </ButtonLink>
              <ButtonLink
                buttonProps={{
                  colorScheme: 'sea-foam',
                  variant: 'outline',
                  width: {
                    base: '100%',
                    md: 'fit-content',
                  },
                  onClick: () =>
                    trackEvent({
                      type: 'click',
                      name: 'Enterprise CTA Clicked',
                      label: 'explore_our_plans_clicked',
                    }),
                }}
                linkProps={{
                  href: '/enterprise/plans',
                }}
              >
                Explore Our Plans
              </ButtonLink>
            </Flex>
          </>
        }
        imageSlot={
          <Box
            flex={{
              base: '1 0 360px',
              md: '1',
            }}
            height={{
              md: '384px',
            }}
          />
        }
      />
      <LogosSection
        companies={pageTemplateData.partners}
        title={pageTemplateData.partnersSectionTitle}
        containerProps={{
          backgroundColor: 'accent.sky.100',
          color: 'black',
        }}
      />
      <UpskillingSection
        description={upskillingSectionImageContentBlock.description}
        image={upskillingSectionImageContentBlock.image}
        title={upskillingSectionImageContentBlock.title}
        footerSlot={
          <UdacityContentSection
            description={contentSectionCopy.text}
            stats={contentSectionStats}
            title={contentSectionCopy.title}
          />
        }
      />
      <UdacityDifferenceSection />
      <LinesBannerSection
        glyphName='email-email-one'
        linkHref='/get-started/enterprise'
        linkText='Contact Us'
        title='**Still have questions about our plans?** Get in touch today.'
        onButtonClick={() => {
          trackEvent({ type: 'click', name: 'Enterprise CTA Clicked', label: 'contact_us_clicked', value: 'CTA 2' });
        }}
      />
      <EnterpriseSuccessSection title={successSectionTitle} />
      <SchoolsCardSection
        description={schoolsSectionTitleText.text}
        schools={schools}
        title={schoolsSectionTitleText.title}
        onClickSchool={(school) => {
          trackEvent({
            type: 'click',
            name: 'Enterprise CTA Clicked',
            label: 'school_card_clicked',
            value: school.title,
          });
        }}
      />
      <UdacityOutcomesSection />
      <TestimonialSection
        companyLogo={testimonial.company?.lofiLogoLight}
        companyName={testimonial.company?.name}
        name={testimonial.name}
        profileImage={testimonial?.profileImage}
        quote={testimonial?.quote}
        title={testimonial.title}
      />
      <ShapesBanner
        {...shapesBanner}
        onPrimaryCtaClick={() =>
          trackEvent({
            type: 'click',
            name: 'Enterprise CTA Clicked',
            label: 'contact_us_clicked',
            value: 'CTA 3',
          })
        }
      />
    </Box>
  );
}
