import { GetStaticPropsContext } from 'next';

import { DEFAULT_LANGUAGE } from '~/features/internationalization/languages';
import { PageHead } from '~/features/page/components/page-head';
import { PageLayout } from '~/features/page/components/page-layout';
import { getPage } from '~/features/page/controllers/get-page';
import { Page } from '~/features/page/models/page';
import { getPageTemplate } from '~/features/page-template/controllers/get-page-template';
import { EnterpriseOverview } from '~/features/page-template/templates/enterprise-overview/components/enterprise-overview';
import {
  EnterpriseOverviewTemplate,
  enterpriseOverviewTemplateQuery,
  enterpriseOverviewTemplatesSchema,
} from '~/features/page-template/templates/enterprise-overview/model';

export default function EnterpriseOverviewPage({
  page,
  enterpriseOverviewTemplate,
}: {
  page: Page;
  enterpriseOverviewTemplate: EnterpriseOverviewTemplate;
}) {
  return (
    <>
      <PageHead page={page} />
      <PageLayout withoutVerticalPadding>
        <EnterpriseOverview pageTemplateData={enterpriseOverviewTemplate} />
      </PageLayout>
    </>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  // Stop additional locale pages except the default locale from being built
  if (locale !== DEFAULT_LANGUAGE) {
    return {
      notFound: true,
    };
  }

  const [page, enterpriseOverviewTemplate] = await Promise.all([
    getPage('/enterprise/overview'),
    getPageTemplate<EnterpriseOverviewTemplate>({
      templateName: 'Enterprise Overview Template',
      templateQuery: enterpriseOverviewTemplateQuery,
      templateZodSchema: enterpriseOverviewTemplatesSchema,
    }),
  ]);

  return {
    props: {
      page,
      enterpriseOverviewTemplate,
    },
  };
}
