import { useMemo } from 'react';

import { Box, Card, chakra, Flex, Heading, Text } from '@chakra-ui/react';

import { SanityImageType } from '~/features/page-template/models/image';

import { ContentContainer } from './container';
import { SanityImage } from './sanity-image';

function QuoteIcon() {
  return (
    <svg fill='none' height='34' viewBox='0 0 36 34' width='36' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.5 0.000976562L9.46875 11.761C9.03125 12.741 8.26567 13.581 7.60943 14.001C6.29693 14.841 5.75005 15.261 5.9688 16.101C6.18755 16.941 6.95313 16.661 7.82812 16.241C8.375 15.961 9.35941 15.681 10.6719 15.681H14.5V33.601H0.5V17.361C0.5 12.461 1.70316 10.781 6.51565 0.000976562H14.5ZM35.5 0.000976562L30.4687 11.761C30.0312 12.741 29.2657 13.581 28.6094 14.001C27.2969 14.841 26.75 15.261 26.9687 16.101C27.1875 16.941 27.9532 16.661 28.8282 16.241C29.3751 15.961 30.3594 15.681 31.6719 15.681H35.5V33.601H21.5001V17.361C21.5001 12.461 22.7031 10.781 27.5156 0.000976562H35.5Z'
        fill='#2015FF'
      />
    </svg>
  );
}

export type TestimonialSectionProps = {
  quote: string;
  title: string | null;
  name: string;
  companyName?: string | null;
  companyLogo?: SanityImageType | null;
  profileImage?: SanityImageType | null;
};

export function TestimonialSection({
  quote,
  name,
  profileImage,
  title,
  companyLogo,
  companyName,
}: TestimonialSectionProps) {
  const [firstSentence, restOfSentences] = useMemo(() => {
    // Define a regular expression to match the first sentence.
    const sentenceRegex = /([A-Z][^.!?]*[.!?])\s+/;
    // Use the regular expression to split the paragraph into sentences.
    const match = sentenceRegex.exec(quote);
    if (match) {
      // The first sentence is the first captured group.
      const firstSentence = match[1];
      // The rest of the sentences are in the second part of the split.
      const restOfSentences = quote.substring(match[0].length);
      return [firstSentence, restOfSentences];
    } else return [];
  }, [quote]);
  return (
    <Box
      as='section'
      bgGradient='linear(to-b, blue.800, #080B40)'
      padding={{
        base: '40px 0px',
        md: '80px 0px',
      }}
    >
      <ContentContainer>
        <chakra.blockquote display='flex' flexDir='column' gap='32px'>
          <Card
            alignItems='center'
            display='flex'
            flexDir='column'
            padding={{
              base: '48px 16px',
            }}
          >
            <Box marginBottom='24px'>
              <QuoteIcon />
            </Box>
            <Text textAlign='center'>
              <Heading
                as='span'
                display='block'
                marginBottom='8px'
                size='marketing-3xl'
                sx={{
                  '&::before': {
                    content: '"“"',
                  },
                }}
              >
                {firstSentence}
              </Heading>
              <Heading
                as='span'
                display='block'
                fontFamily='heading'
                fontWeight='normal'
                size='h4'
                sx={{
                  '&::after': {
                    content: '"”"',
                  },
                }}
              >
                {restOfSentences}
              </Heading>
            </Text>
          </Card>
          <chakra.footer alignItems='center' display='flex' gap='24px' justifyContent='space-between'>
            <Flex alignItems='center' gap='24px'>
              {profileImage && <SanityImage alt='' height={90} image={profileImage} width={90} />}
              <Box color='white'>
                <Heading as='p' size='marketing-2xl'>
                  {name}
                </Heading>
                <Text>{title}</Text>
              </Box>
            </Flex>
            <Box display={{ base: 'none', md: 'block' }}>
              {companyLogo && companyName && <SanityImage alt={companyName} image={companyLogo} />}
            </Box>
          </chakra.footer>
        </chakra.blockquote>
      </ContentContainer>
    </Box>
  );
}
