import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react';

import { ContentContainer } from '~/components/container';

export type UdacityContentSectionProps = {
  title: string;
  description: string;
  stats: Array<{
    _id: string;
    _type: 'titleText';
    title: string;
    text: string;
  }>;
};

export function UdacityContentSection({ title, description, stats }: UdacityContentSectionProps) {
  return (
    <Box as='section'>
      <ContentContainer>
        <Box marginBottom='48px' textAlign='center'>
          <Heading as='h3' marginBottom='16px' size='h2'>
            {title}
          </Heading>
          <Text>{description}</Text>
        </Box>
        <Grid
          gap='32px'
          gridTemplateColumns={{
            md: 'repeat(auto-fit, minmax(256px, 1fr))',
          }}
        >
          {stats.map(({ title, text, _id }) => (
            <Flex
              key={_id}
              as='article'
              backgroundColor='accent.sky.100'
              borderRadius='24px 8px'
              flex='1 1 256px'
              flexDirection='column'
              height='200px'
              justifyContent='center'
              padding='40px 24px'
              textAlign='center'
            >
              <Heading as='p' color='blue.500' size='marketing-3xl'>
                {title}
              </Heading>
              <Text fontWeight='600'>{text}</Text>
            </Flex>
          ))}
        </Grid>
      </ContentContainer>
    </Box>
  );
}
