import Image from 'next/image';

import { Box, Flex, List, ListItem, Text } from '@chakra-ui/react';

import { ContentContainer } from '~/components/container';
import { Glyph } from '~/components/glyph';
import { HeadingWithEmphasis } from '~/components/heading-with-emphasis';

export type EnterpriseSuccessSectionProps = {
  title: string;
};

export function EnterpriseSuccessSection({ title }: EnterpriseSuccessSectionProps) {
  return (
    <Box
      as='section'
      bgGradient='linear(to-b, white, accent.sky.100)'
      padding={{
        base: '40px 0px',
        md: '80px 0px',
      }}
    >
      <ContentContainer display='flex' gap='32px'>
        <Box flex='0 1 736px'>
          <HeadingWithEmphasis
            text={title}
            emphasisProps={{
              fontWeight: '500',
              color: 'blue.500',
            }}
            headingProps={{
              size: 'marketing-4xl',
              as: 'h2',
              marginBottom: '48px',
            }}
          />
          <List
            display='grid'
            gridGap={{
              base: '32px',
              md: '32px 56px',
            }}
            gridTemplateColumns={{
              md: 'repeat(2, 1fr)',
            }}
          >
            <ListItem>
              <Flex as='article' flexDir='column' gap='8px'>
                <Glyph height={64} name='money-dollar-one' width={64} />
                <Text>Reduced external hiring and consulting costs due to upskilling existing talent</Text>
              </Flex>
            </ListItem>
            <ListItem>
              <Flex as='article' flexDir='column' gap='8px'>
                <Glyph height={64} name='chart-two-two' width={64} />
                <Text>Impressive ROI and short payback window on upskilling investment</Text>
              </Flex>
            </ListItem>
            <ListItem>
              <Flex as='article' flexDir='column' gap='8px'>
                <Glyph height={64} name='chart-bar-up-arrow-one' width={64} />
                <Text>Increased employee productivity in-role due to new skills acquired</Text>
              </Flex>
            </ListItem>
            <ListItem>
              <Flex as='article' flexDir='column' gap='8px'>
                <Glyph height={64} name='hand-thumbs-up-one' width={64} />
                <Text>Higher employee satisfaction and reduced churn</Text>
              </Flex>
            </ListItem>
          </List>
        </Box>
        <Box
          flex='1'
          position='relative'
          display={{
            base: 'none',
            xl: 'block',
          }}
        >
          <Image
            alt=''
            sizes='352px'
            src='/images/backgrounds/floating-shapes-2.png'
            style={{
              objectFit: 'contain',
            }}
            fill
          />
        </Box>
      </ContentContainer>
    </Box>
  );
}
