import Image from 'next/image';

import { Box, Flex, Heading, Text } from '@chakra-ui/react';

import { ContentContainer } from '~/components/container';

type Item = {
  title: string;
  subtitle: string;
  description: string;
  imageSrc: string;
};

const initItems: Item[] = [
  {
    title: 'Speed to Mastery',
    subtitle: `Discover a learning solution that's as agile as your team.`,
    description: `From Discovery courses to Nanodegrees, Udacity delivers industry-leading education designed to accelerate your organization’s journey to technical mastery. `,
    imageSrc: '/images/speed-to-mastery.png',
  },
  {
    title: 'Personalized, Project-Based, and Scalable',
    subtitle: `Transform potential into expertise with Udacity's hybrid learning model, which delivers up-skilling at scale with a hands-on approach.`,
    description: `Our flexible learning paths blend self-paced content, on-demand mentorship, and classroom-style interaction, ensuring a learning experience that's as unique as your business needs.`,
    imageSrc: '/images/personalized-project-based-scalable.png',
  },
  {
    title: 'Real-World Outcomes',
    subtitle: 'Acquire the skills needed to innovate and outpace your competition.',
    description: `Our customers have saved millions in hiring costs and operational efficiency. Partner with Udacity to not only up-skill your workforce but to drive significant business outcomes, innovation from within, and employee satisfaction.`,
    imageSrc: '/images/real-world-outcomes.png',
  },
];

type UdacityDifferenceSectionProps = {
  title?: string;
  items?: Item[];
};

export function UdacityDifferenceSection({
  title = 'What makes Udacity different?',
  items = initItems,
}: UdacityDifferenceSectionProps) {
  return (
    <Box as='section' backgroundColor='gray.100' padding={{ base: '40px 0px', md: '80px 0px' }}>
      <ContentContainer>
        <Heading as='h2' marginBottom='48px' size='marketing-5xl' textAlign='center'>
          {title}
        </Heading>
        <Flex
          flexDir='column'
          gap={{
            base: '80px',
            md: '120px',
          }}
        >
          {items.map((item, idx) => {
            const isImageRight = idx % 2 === 0;
            return (
              <Flex
                key={item.title}
                alignItems='center'
                as='article'
                flexFlow={isImageRight ? 'row' : 'row-reverse'}
                gap='96px'
              >
                <Box
                  flex={{
                    md: '0 2 544px',
                  }}
                >
                  <Heading
                    as='h3'
                    color={isImageRight ? 'blue.500' : 'accent.sea-foam.500'}
                    fontWeight='600'
                    size='marketing-5xl'
                  >
                    {item.title}
                  </Heading>
                  <Heading as='p' marginTop='16px' size='marketing-2xl'>
                    {item.subtitle}
                  </Heading>
                  <Text marginTop='16px'>{item.description}</Text>
                </Box>
                {item.imageSrc && (
                  <Box
                    flex='1 1 544px'
                    height='556px'
                    overflow='hidden'
                    position='relative'
                    display={{
                      base: 'none',
                      md: 'block',
                    }}
                  >
                    <Image
                      alt=''
                      sizes='544px'
                      src={item.imageSrc}
                      style={{
                        objectFit: 'contain',
                        pointerEvents: 'none',
                      }}
                      fill
                    />
                  </Box>
                )}
              </Flex>
            );
          })}
        </Flex>
      </ContentContainer>
    </Box>
  );
}
