import Image from 'next/image';

import { Box, Flex } from '@chakra-ui/react';
import { ArrowRightIcon } from '@udacity/chakra-uds-icons';

import { HeadingWithEmphasis } from '~/components/heading-with-emphasis';

import { ButtonLink } from './button-link';
import { ContentContainer } from './container';
import { Glyph } from './glyph';

export type LinesBannerSectionProps = {
  title: string;
  linkText?: string;
  linkHref: string;
  onButtonClick?: () => void;
  glyphName?: string;
};

export function LinesBannerSection({ title, linkHref, linkText, onButtonClick, glyphName }: LinesBannerSectionProps) {
  return (
    <Box
      as='section'
      backgroundColor='blue.800'
      overflow='hidden'
      position='relative'
      padding={{
        base: '24px 0px',
        md: '64px 0px',
      }}
    >
      <Image
        alt=''
        quality={100}
        sizes='1440px'
        src='/images/backgrounds/blue-lines-wide.png'
        style={{ objectFit: 'cover', objectPosition: 'bottom', pointerEvents: 'none' }}
        fill
      />
      <ContentContainer position='relative'>
        <Flex
          alignItems={{
            base: 'left',
            md: 'center',
          }}
          flexDir={{
            base: 'column',
            md: 'row',
          }}
        >
          {glyphName && (
            <Box
              margin={{
                base: '0px 0px 16px 0px',
                md: '0px 16px 0px 0px',
              }}
            >
              <Glyph height={64} name={glyphName} width={64} />
            </Box>
          )}
          <HeadingWithEmphasis
            text={title}
            emphasisProps={{
              fontWeight: '500',
            }}
            headingProps={{
              color: 'white',
              fontWeight: '400',
              as: 'h2',
              size: 'h3',
              flex: {
                base: 'unset',
                md: '1',
              },
              marginBottom: {
                base: '36px',
                md: 'unset',
              },
            }}
          />
          <ButtonLink
            buttonProps={{
              variant: 'solid',
              size: 'icon-right',
              rightIcon: <ArrowRightIcon h='24px' w='24px' />,
              colorScheme: 'sea-foam',
              onClick: onButtonClick,
            }}
            linkProps={{
              href: linkHref,
            }}
          >
            {linkText}
          </ButtonLink>
        </Flex>
      </ContentContainer>
    </Box>
  );
}
