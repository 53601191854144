import { ReactNode } from 'react';

import { Box, Text } from '@chakra-ui/react';

import { ContentContainer } from '~/components/container';
import { HeadingWithEmphasis } from '~/components/heading-with-emphasis';
import { SanityImage } from '~/components/sanity-image';
import { SanityImageType } from '~/features/page-template/models/image';

export type UpskillingSectionProps = {
  title: string;
  description: string;
  image: SanityImageType | null;
  footerSlot?: ReactNode;
};

export function UpskillingSection({ title, description, image, footerSlot }: UpskillingSectionProps) {
  return (
    <Box
      as='section'
      padding={{
        base: '40px 0',
        md: '80px 0',
      }}
    >
      <ContentContainer
        alignItems='center'
        display='flex'
        gap='32px'
        marginBottom='80px'
        flexDirection={{
          base: 'column-reverse',
          md: 'row',
        }}
      >
        <Box
          borderRadius='lg'
          overflow='hidden'
          position='relative'
          width='100%'
          flex={{
            base: '1 0 290px',
            md: '1 1 544px',
          }}
          height={{
            base: '290px',
            md: '460px',
          }}
        >
          {image && <SanityImage alt='' image={image} sizes='1088px' style={{ objectFit: 'cover' }} fill />}
        </Box>
        <Box
          flex={{
            base: '1',
            md: '0 2 544px',
          }}
        >
          <HeadingWithEmphasis
            text={title}
            emphasisProps={{
              color: 'blue.500',
              fontWeight: '500',
            }}
            headingProps={{
              size: 'marketing-5xl',
              as: 'h2',
              marginBottom: '40px',
            }}
          />
          <Text>{description}</Text>
        </Box>
      </ContentContainer>
      {footerSlot}
    </Box>
  );
}
